$base-font: Lato;
$text-color-primary: #474747;
$text-color-secondary: #767676;
$text-color-tertiary: #1bace4;

$quote-avaialable-status-bg-color: #4a90e2;
$quote-in-progress-bg-color: #ff9800;
$quote-won-bgn-color: #32a728;
$border-color: #ededed;

$image-border-color: #ccc;
$image-bg-color: #ededed;

.my-quotes {
  margin: 30px 0 0 0;
  font-family: Lato;
  font-style: normal;
  letter-spacing: 0px;

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 0 16px;
  }

  @media (min-width: 1200px) {
    margin-left: 16px;
  }

  .quote-img-wrapper {
    align-items: flex-end;
    background-color: $image-bg-color;
    border-radius: 50% !important;
    border: 1px solid $image-border-color;
    display: flex;
    height: 80px;
    justify-content: center;
    margin: 0 16px 0 0;
    overflow: hidden;
    width: 80px;
  }

  .quote-img {
    width: 40px;
    height: auto;
    margin: 0 16px 0 0;
  }

  .dashboard-quote-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .quote-item {
      align-items: flex-start;
      border-bottom: 1px solid $border-color;
      display: flex;
      padding: 16px 0 16px 16px;
      background-color: white;

      .quote-info {
        width: 100%;

        .quote-title-wrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          @media (max-width: 768px) {
            flex-wrap: wrap;
            padding-right: 1rem;
          }

          .quote-name-link-wrapper {
            display: flex;
            justify-content: flex-start;
            margin-right: 5px;
          }

          .quote-name {
            color: $text-color-primary;
            display: inline-block;
            font-size: 20px;
            font-weight: bold;
            line-height: 22px;
            margin: 0;
            text-transform: uppercase;
            max-width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-decoration: none;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          .quote-link {
            margin-left: 5px;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            text-align: left;
            color: $text-color-secondary;
            text-decoration: none;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          .quote-status {
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            text-align: center;
            background-color: white;
            color: white;
            padding: 2px;
            border-radius: 4px;
            height: 20px;
            min-width: 100px;
            margin: 5px 0;
            @media (min-width: 768px) {
              margin: 5px 0;
            }
            &.available {
              background-color: $quote-avaialable-status-bg-color;
            }

            &.validated {
              background-color: $quote-won-bgn-color;
            }
          }
        }

        .quote-details {
          color: $text-color-secondary;
          padding: 0;
          margin: 0;

          .jobsite-name {
            line-height: 16px;
            font-size: 12px;
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 168px;
            display: inline-block;
            margin: 0;
            vertical-align: middle;
            color: $text-color-secondary;
          }

          .enquiry-count {
            line-height: 16px;
            font-size: 12px;
            font-weight: 700;
            color: $text-color-primary;
          }
        }
      }
    }
  }
}

.show-mobile .my-quotes {
  @media (min-width: 768px) {
    margin-left: 0;
    margin-right: 16px;
  }
}
